import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Seo } from "../components/";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="About" keywords={[`about`, `amberley  romo`, `bio`]} mdxType="Seo" />
    <h1 {...{
      "id": "about-me"
    }}>{`About me`}</h1>
    <p>{`Hey. My name is Amberley. I am a senior frontend engineer with `}<a parentName="p" {...{
        "href": "https://twitter.com/amber1ey/status/1094655492545228800"
      }}>{`over ten years of experience building for the web`}</a>{`. My main interest and focus in the discipline of frontend engineering is to `}<a parentName="p" {...{
        "href": "https://a11y.coffee/"
      }}>{`make the web more accessible`}</a>{`. My happy place is collaborating with kind, compassionate folks in building things that have a positive impact on peoples’ lives.`}</p>
    <h2 {...{
      "id": "support"
    }}>{`Support`}</h2>
    <p>{`People and organizations I regularly support.`}</p>
    <h3 {...{
      "id": "recurring-support-organizations"
    }}>{`Recurring support, organizations`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.marbridge.org/"
        }}>{`Marbridge`}</a>{`, a non-profit residential community in Austin, TX supporting adults with intellectual and developmental disabilities.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.arcaustin.org/"
        }}>{`The Arc of the Capital Area`}</a>{`, a non-profit organization committed to empowering Central Texans with intellectual and developmental disabilities and their families through compassionate case management and innovative programs.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.open-austin.org/"
        }}>{`Open Austin`}</a>{`, a volunteer citizen brigade advocating for open government, open data, and civic application development.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://brookwoodingeorgetown.org/"
        }}>{`Brookwood in Georgetown`}</a>{`, a non-profit day vocational program for adults with intellectual and developmental disabilities.`}</li>
    </ul>
    <h3 {...{
      "id": "recurring-support-individuals"
    }}>{`Recurring support, individuals`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.patreon.com/diversifytech/posts"
        }}>{`Veni Kunche`}</a>{`, building Diversify Tech.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/users/tatianamac/sponsorship"
        }}>{`Tatiana Mac`}</a>{`, an independent designer pushing forward rebuilding tech with inclusion, accessibility, web performance, and ethics at the core.`}</li>
    </ul>
    <h3 {...{
      "id": "ad-hoc-support"
    }}>{`Ad Hoc support`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mypossibilities.org/"
        }}>{`My Possibilities`}</a>{`, a non-profit organization serving adults with disabilities -- the first full-day, full-year educational program of its kind in Collin County, TX.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.hotlabrescue.org/hotlab/index.jsp"
        }}>{`Heart of Texas Lab Rescue`}</a>{`, a non-profit lab rescue in Austin, TX.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.lilithfund.org/"
        }}>{`Lilith Fund`}</a>{`, a non-profit organization supporting the right of all Texans to make their own reproductive choices, regardless of income.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://austinjustice.org/"
        }}>{`Austin Justice Coalition`}</a>{`, a Racial Justice Group that educates and builds community power for people of color who live in Austin, Texas that need support, community, and liberation during a time of systemic injustice in America.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.thetrevorproject.org/"
        }}>{`The Trevor Project`}</a>{`, a suicide prevention and crisis intervention organization for LGBTQ (lesbian, gay, bisexual, transgender, queer, and questioning) young people.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.themarshallproject.org/"
        }}>{`The Marshall Project`}</a>{`, non-profit journalism about criminal justice.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wck.org/"
        }}>{`World Central Kitchen`}</a>{`, an organization providing meals in response to humanitarian, climate, and community crises.`}</li>
    </ul>
    <h3 {...{
      "id": "journalism-support"
    }}>{`Journalism support`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.texastribune.org/"
        }}>{`Austin Chronicle`}</a>{`, local news.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.kut.org/"
        }}>{`KUT Radio`}</a>{`, local NPR affiliate.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.texastribune.org/"
        }}>{`The Texas Tribune`}</a>{`, local/state news.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.washingtonpost.com/"
        }}>{`The Washington Post`}</a>{`, national news.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.economist.com/"
        }}>{`The Economist`}</a>{`, world news.`}</li>
    </ul>
    <h2 {...{
      "id": "gratitude"
    }}>{`Gratitude`}</h2>
    <p>{`I'm immensely grateful to everyone who has encouraged me, mentored me, and believed in me over the course of growing my career in software. There are
too many to count, but a few in particular have been absolutely vital. I want to carve a little web space here to express that gratitude.`}</p>
    <h3 {...{
      "id": "dave-kennedy"
    }}>{`Dave Kennedy`}</h3>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/davidakennedy"
      }}>{`Dave`}</a>{` and I were co-workers at The Arc of the United States, the national office of a non-profit organization serving people with intellectual and development disabilities.`}</p>
    <p>{`For the first part of my time at The Arc, I went from a PR intern, to a marketing intern, to a part-time employee doing graphic design, marketing support and brand implementation and enforcement. At the time, Dave was a one-man show running anything website related.`}</p>
    <p>{`I loved our chats about the web. He was the first person I ever talked to who believed I could -- and should, if I wanted -- make a career out of working on the web. Previously, I thought of my static site creation only as tinkering, or through the lens of graphic design / marketing.`}</p>
    <p>{`When he ultimately left, he recommended that the non-profit hire me into an open position on the web team. And they did. And that was my first web job.`}</p>
    <p>{`Without Dave, I don't know that I would have ever believed that this industry was for me. He also had (and still has) such a passion for web accessibility, which hugely influenced the way I think about the web.`}</p>
    <h3 {...{
      "id": "maggie-epps"
    }}>{`Maggie Epps`}</h3>
    <p>{`I met `}<a parentName="p" {...{
        "href": "https://twitter.com/meppstimist"
      }}>{`Maggie`}</a>{` while we were both working at the same digital agency. She was the first female engineer hired there. At the time I was a technical project manager.`}</p>
    <p>{`When I came on board at the agency, I had been trying to pivot into a role doing dev work where I could learn from other devs. For boring reasons I won't elaborate on, I ended up deciding the PM role was a good move.`}</p>
    <p>{`Maggie advocated passionately to diversify the staff -- particularly the engineering staff. And we had so many hugely important discussions that ultimately resulted in me believing in myself enough to quit my job, move to a different city, do a code bootcamp, and keep working toward being a software developer.`}</p>
    <h3 {...{
      "id": "wes-todd"
    }}>{`Wes Todd`}</h3>
    <p>{`After I finished the bootcamp, `}<a parentName="p" {...{
        "href": "https://twitter.com/wesleytodd"
      }}>{`Wes`}</a>{` hired me as a junior software developer. It wasn't my first "dev" job -- I'd been doing dev work for years already at this point. (No one knows how to adequately explain this. See "`}<a parentName="p" {...{
        "href": "https://css-tricks.com/the-great-divide/"
      }}>{`The Great Divide`}</a>{`" by Chris Coyier). But he saw potential in me as a software developer, and I'll forever be grateful to him for giving me the opportunity to breathe new life into my career after finishing the bootcamp.`}</p>
    <p>{`He was an amazing manager, mentor and friend. He loves to have strong opinions and debate things, and always offered detailed, constructive feedback on PRs. He's super passionate about the open source community, and has an energizing curiosity about the software ecosystem as a whole. I learned so much from him, and I'm so grateful.`}</p>
    <h3 {...{
      "id": "aaron-gustafson"
    }}>{`Aaron Gustafson`}</h3>
    <p>{`I met `}<a parentName="p" {...{
        "href": "https://twitter.com/aarongustafson"
      }}>{`Aaron`}</a>{` when I saw him put out a call for "mentees" for the year of 2017, and I applied. I was one of his `}<a parentName="p" {...{
        "href": "https://www.aaron-gustafson.com/notebook/my-2017-mentees/"
      }}>{`first two mentees`}</a>{`.`}</p>
    <p>{`During that year, I dealt with a lot personally, including treating and managing severe, debilitating depression. That, combined with imposter syndrome, created a toxic mix that very nearly convinced me I was not cut out for this industry. Aaron's continued guidance, support, and advice frankly kept me in the industry.`}</p>
    <p>{`While I regret not being able to take advantage of that mentorship year in the way I wish I could have, it was no doubt absolutely what I needed at the time.`}</p>
    <h3 {...{
      "id": "jason-lengstorf"
    }}>{`Jason Lengstorf`}</h3>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/jlengstorf"
      }}>{`Blitz Jackson`}</a>{`. It's hard to overstate how grateful I am to know Jason. I met Jason when I joined Gatsby. I was the first IC he managed at Gatsby -- technically organized in the Dev Rel area, but focused on supporting the ecosystem and documentation, and not so much traditionally Dev Rel-ish (`}<em parentName="p">{`Lol, rel-ish? Punny sticker idea, Jason?`}</em>{`) stuff.`}</p>
    <p>{`He never doubted me. Working alongside someone who whole-heartedly and energetically believes in you is so validating, it felt like it gave me super powers. I have debilitating anxiety when it comes to presenting information to large audiences -- but in 2019, organized under Dev Rel, I wanted to do my best. He actively supported me in preparing for all of the speaking engagements I pushed myself to undertake in that year.`}</p>
    <p>{`He taught me a lot about believing in yourself and having confidence in your credibility. And even when that wavers (as it does, for this chronic case of imposter syndrome I have) -- I can quiet my lizard brain a little bit better than I could before. And he taught me a lot about unapologetically advocating for what you believe in, and staying grounded in your sense of self, in that effort.`}</p>
    <h3 {...{
      "id": "marcy-sutton"
    }}>{`Marcy Sutton`}</h3>
    <p>{`I have known `}<strong parentName="p">{`of`}</strong>{` `}<a parentName="p" {...{
        "href": "https://twitter.com/marcysutton"
      }}>{`Marcy`}</a>{` for longer than I can recall -- much, much longer than I have known her personally. Her educational content on accessibility is so high quality and so commonly cited that the IAAP counts it toward their continuing education credit.`}</p>
    <p>{`When she joined Gatsby, I was beside myself at the opportunity to work alongside her. I've gotten to learn from her in so many ways -- on a11y, on documentation and information architecture, on advocating for what you believe in, and on using your privilege to try to make an impact where you can. I've gotten to hop on video calls with her to audit UI for accessibility together, and tag her on Slack in questions about accessible UI development and patterns. I'm still in awe that I had the opportunity not only to benefit from having access to such a high caliber mentor (in a11y and beyond) but also to become her friend.`}</p>
    <h3 {...{
      "id": "leslie-cohn-wein"
    }}>{`Leslie Cohn-Wein`}</h3>
    <p>{`Leslie and I were first Twitter mutuals, and I had the opportunity to meet her in person at several ReactJS Dallas meetups. Part of my excitement when I joined Netlify was that I would finally have the opportunity to work directly with Leslie. We worked together as the founding frontend engineers on Netlify's first Growth team. As engineering peers, we worked together to grow the engineering approach and culture in the fledgling Growth team, which for a period of time did not have an Engineering Manager.`}</p>
    <p>{`Leslie's instincts in balancing quality and velocity in shipping are unmatched. She is deft at parsing ambiguous requirements and situations into clear actions, with dependable follow-through, and has an uncanny natural ability to distill chaos into order. She has no ego in her work, whether it's code or process, and will discard what isn't working and pivot, and build on what is working. I got to go from working directly with her as peer ICs directly to being one of her first reports as an interim engineering manager. These skills I witnessed as peer ICs translated seamlessly into engineering management, making her one of the most effective engineering managers I’ve ever seen, right off the bat, because realistically she was already building those muscles during her time as an IC. I was worried about going from mutuals, to peers, to manager and report, but she was also able to balance that well, right off the bat.`}</p>
    <p>{`She is a patient and empathetic peer and leader, and singularly kind-hearted and welcoming. She's also probably the most versatile and well-rounded individual I've ever had the opportunity to work with. I'm sad to no longer work with her (for now! I certainly hope to again), but it's been wonderful to get to just be friends out of the context of work.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      